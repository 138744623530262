<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.newContract')"
    
  >
  
    <!-- <div id="outer-title">{{ $t("i18n.newContract") }}</div> -->
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.newContract") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="columns"
          @onSubmit="onSubmit"
          @resetForm="setIsEdit(true)"
        ></jl-form>
      </div>
    </el-card>
    <!-- <div class="half-form">
      <jl-form :columns="columns" @onSubmit="onSubmit"></jl-form>
    </div> -->
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlForm from "../../components/form";
export default {
  name: "ContractAdd",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "maintenanceContractNo",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "signDate",
          label: "dateOfSigning",
          type: "date",
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "directSelling",
          label: "directSelling",
          type: "select",
          props: { label: "name", value: "id" },
          data: [{"id":"true", "name":"是"},{"id":"false", "name":"否"}],
        },
        {
          prop: "alignment",
          label: "debugOrNot",
          type: "select",
          props: { label: "name", value: "id" },
          data: [{"id":"true", "name":"是"},{"id":"false", "name":"否"}],
        },
        {
          prop: "projectLeader",
          label: "areaManager",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "address",
          label: "projectAddress",
          type: "input",
        },
        {
          prop: "projectTelephone",
          label: "contractInformation",
          type: "input",
        },
        {
          prop: "firstPartyName",
          label: "partyA",
          type: "input",
        },
        {
          prop: "secondPartyName",
          label: "partyB",
          type: "input",
        },
        {
          prop: "firstPartySignature",
          label: "representativeA",
          type: "input",
        },
        {
          prop: "secondPartySignature",
          label: "representativeB",
          type: "input",
        },
        {
          prop: "firstPartyAddress",
          label: "addressA",
          type: "input",
        },
        {
          prop: "secondPartyAddress",
          label: "addressB",
          type: "input",
        },
        {
          prop: "firstPartyPhone",
          label: "telephoneA",
          type: "input",
        },
        {
          prop: "secondPartyPhone",
          label: "telephoneB",
          type: "input",
        },
        {
          prop: "manuscript",
          label: "originalcontract",
          type: "upload",
          action: "contracts",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
        },
      ],
    });
    const init_data = () => {
      getBillPerson();
    };
    
    const getAllPersons = async (column) => {
      // 电梯工号 change
      //let obj = { elevatorId: val.id, name: "" };
      let { data } = await proxy.$api.maintenance.getBillPerson();
      // var index = state[column].findIndex((item) => {
      //   return item.prop === key;
      // });
      state[column][0].data = data;
    };
    const getBillPerson = async () => {
      let { data } = await proxy.$api.maintenance.getBillPerson();
      state.columns.map((item) => {
        if (item.prop === "projectLeader") {
          item.data = data;
        }
      });
    };

    const onSubmit = async (row) => {
      await proxy.$api.system.isExistContract({
        number: row.number,
        projectName: row.projectName,
      });
      addContract(row);
    };

    const addContract = async (params) => {
      let data = JSON.parse(JSON.stringify(params));
      if (data.signDate) {
        data.signDate = proxy.$defined.format(data.signDate);
      }
      await proxy.$api.system.addInstallContract(data);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      router.go(-1);
    };
    init_data();
    return {
      ...toRefs(state),
      onSubmit,
      getAllPersons,
      
    };
  },
};
</script>

<style lang="scss">
.contractAdd {
  .form-button {
    text-align: center;
    margin-left: 0 !important;
    width: 100% !important;
    .el-form-item__content {
      margin: 0 !important;
    }
  }
}
</style>
